import styled from 'styled-components';

export const WorkPage = styled.div `
    width: 100vw;
    padding: 100px 0 0;
    text-align: center;
    background-color: white;
    background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/hero_photo@300x.png);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    @media screen and (max-width: 700px) {
        background-attachment: unset;
     }

    h2 {
        color: #cf202c;
        font-weight: 400;
        font-size: 250%;
        @media screen and (max-width: 700px) {
            margin-bottom: 15px;
         }
    } 

    p{
        width: 60%;
        margin-left: 20%;
        color: #444;
        @media screen and (max-width: 700px) {
            width: 80%;
            margin-left: 10%;
            margin-bottom: 25px;
         }
    }
`;

export const WorkSectionRow = styled.div `
    width: 100%;   
    display: flex; 
    justify-content: space-between;
    padding: 20px;
    background-color: #000;
    background-image: linear-gradient(rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/background_pattern.png);
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 700px) {
        display: block;
     }
`;

export const WorkSectionBreak = styled.div `
    height: 30px;
    background-color: white;
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/hero_photo@300x.png;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    @media screen and (max-width: 700px) {
        display: none;
     }
`;

export const WorkSectionColumns = styled.div `
    width: 32%;
    @media screen and (max-width: 700px) {
        width: 90%;
        margin-left: 5%;
        padding: 15px;
     }
`;