import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledMenu = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    height: 100vh;
    padding: 6em 8em 1.5em 8em;
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: right;
    width: 100%;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    z-index: 600;
    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 4em 4em 0 4em;
        alight-items: center;
    }
`;

export const PageLink = styled(Link)
`
    width: 100%;
    margin: 5% 0 1% 0;
    border-bottom: 1px solid #cf202c;
    padding: 10px 20px;
    cursor: pointer;
    color: #222;
    &:hover {
        color: #cf202c;
    }
`;