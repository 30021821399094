import React from 'react';
import axios from 'axios';

import FormInput from '../form-input/form-input.component';
import TextArea from '../form-input/text-area.component';

import './contact-form.styles.scss'
import WithSpinner from '../with-spinner/with-spinner.component';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050';

axios.defaults.withCredentials = true;

class ContactUs extends React.Component {
    constructor(){
        super();

        this.state = {
            contactName: '',
            email: '',
            phone: '',
            message: '',
            company: '',
            isLoading: false,
            token: null,
            captchaSuccess: false,
            disabled: true
        }
    }

    clearState = () => {
        this.setState({
        contactName: '',
        email: '',
        phone: '',
        message: '',
        company: '',
        isLoading: false,
    });
    }
/*
    componentDidMount = () => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if(!isScriptExist) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.id = id;
                script.onload = function() {
                    if(callback) callback();
                }
                document.body.appendChild(script);
            }
            if(isScriptExist && callback) callback();
        }
        loadScriptByURL(`${SITE_KEY}`, `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function() {
            console.log('scriptLoaded');
        });
    } 

    handleSubmit = async event => {
        event.preventDefault();

        const {contactName, email, phone, message, company} = this.state;

        const emailBody = {
            "your-name": `${contactName}`,
            "your-company": `${company}`,
            "your-email": `${email}`,
            "your-phone": `${phone}`,
            "your-message": `${message}`
        }

        const form = new FormData();
        for (const field in emailBody) {
            form.append(field, emailBody[field]);
        }    

        const url = `${API_URL}/wp-json/contact-form-7/v1/contact-forms/23/feedback`

        this.setState({isLoading: true});
           if(this.state.captchaSuccess === true){
                try{
                    await axios.post(url, form)
                    .then(function(response) {
                        console.log(response);
                        if(response.data.status === 'mail_sent') {
                            window.alert("Thank Your For Your Submission. We Will Reply Shortly")
                        } else {
                            window.alert("Your Submission Failed. Please Confirm All Fields Are Valid")
                        }
                    }).then(this.clearState);          
                } catch(error) {
                    console.log(error);
                    window.alert('Something went wrong, please try again');
                    this.setState({isLoading: false});
                }
           } else {
                window.alert('Google ReCaptcha Failed. Please check your browser settings and try again.');
                this.setState({isLoading: false});
            }    

    }

    handleChange = event => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    handleToken = async (token) => {
        this.setState({token: token});
        const token_req = {
            "token": `${token}`
        }
        try{
            await axios.post(`${API_URL}/recaptcha.php`, token_req).then(res => {
                console.log(res);
                    if(res.data === 'pass'){
                        this.setState({captchaSuccess: true});
                        this.setState({disabled: false});
                        
                    } else {
                        console.log('fail');
                    }
            });
        }catch{
            this.setState({isLoading: false});
        }
    }

    handleExpired = () => {
        this.setState({token: null})
    }

    */

    handleSubmit = async event => {
        event.preventDefault();

        const {contactName, email, phone, message, company} = this.state;

        const emailBody = {
            "your-name": `${contactName}`,
            "your-company": `${company}`,
            "your-email": `${email}`,
            "your-phone": `${phone}`,
            "your-message": `${message}`
        }

        const form = new FormData();
        for (const field in emailBody) {
            form.append(field, emailBody[field]);
        }    

        const url = `${API_URL}/wp-json/contact-form-7/v1/contact-forms/23/feedback`

        this.setState({isLoading: true});
                try{
                    await axios.post(url, form)
                    .then(function(response) {
                        if(response.data.status === 'mail_sent') {
                            window.alert("Thank Your For Your Submission. We Will Reply Shortly")
                        } else {
                            window.alert("Your Submission Failed. Please Confirm All Fields Are Valid")
                        }
                    }).then(this.clearState);          
                } catch(error) {
                    window.alert('Something went wrong, please try again');
                    this.setState({isLoading: false});
                } 

    }

    handleChange = event => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    render() {

        const { contactName, email, phone, message, company, isLoading } = this.state;

        if(isLoading === false) {

        return (        
            <div className='contact-form-block'>
                <h2 className='separator'>Contact Us</h2>
                <form className='contact-form' onSubmit={(this.handleSubmit)}>
                    <FormInput 
                        type='text'
                        name='contactName'
                        value={contactName}
                        onChange={this.handleChange}
                        label='Name*'
                        required
                        aria-label='Your Name'
                    >
                    </FormInput>
                    <FormInput 
                        type='text'
                        name='company'
                        value={company}
                        onChange={this.handleChange}
                        label='Company'
                        aria-label='Your Company'
                    ></FormInput>                    
                    <FormInput 
                        type='email'
                        name='email'
                        value={email}
                        onChange={this.handleChange}
                        label='Email*'
                        required
                        aria-label='Your Email'
                    />
                    <FormInput 
                        type='text'
                        name='phone'
                        value={phone}
                        onChange={this.handleChange}
                        label='Phone'
                        aria-label='Your Phone Number'
                    />
                    <TextArea
                        name='message'
                        value={message}
                        onChange={this.handleChange}
                        label='Message*'
                        required
                        aria-label='Your Message'
                    ></TextArea>
                    <button 
                        className='submit-btn'
                        type='submit'>SUBMIT                        
                    </button>            
                </form>
            </div>
        )
        } else {
            return(
                <div className='contact-form-block'>
                <h2 className='separator'>Message Sending</h2>
                <WithSpinner />
                </div>
            )
        }
    }
}

export default ContactUs;