import React from "react";
import { AddressIcon, ContactInfoBlock, MailIcon, PhoneIcon } from "./contact_info.stlyes";

export const ContactInfoMobile = () => {
    return(
        <ContactInfoBlock>
            <AddressIcon/>
            <p>P.O. Box 233 Timnath, CO 80547</p><br/>
            <MailIcon/>
            <a href='mailto:cameron@thehoodguy.com'><p>cameron@thehoodguy.com</p></a><br/>
            <PhoneIcon/>
            <a href='tel:970-219-3257'><p>Northern CO: 970-219-3257</p></a>
            <a href='tel:720-219-8286'><p>Denver: 720-219-8286</p></a><br/>            
        </ContactInfoBlock>
    );
}