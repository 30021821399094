import styled from 'styled-components';

export const LandingBlock = styled.div `
    width: 100vw;
    height: 100vh;
    padding-top: 100px;
    background-image: linear-gradient(rgba(255,255,255, 0.75), rgba(255,255,255, 0.75)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/hero_photo@300x.png);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    @media screen and (max-width: 700px) {
        background-attachment: unset;
     }
`;

export const HeroLogo = styled.img `
    width: 70vw;
    margin-top: 20vh;
    max-height: 40vh;
    z-index: 2;
    @media screen and (max-width: 600px) {
        width: 80vw;
     }
`;

export const ExperienceType = styled.h1 `
    color: #222;
    margin-top: 75px;
    font-size: 250%
    @media screen and (max-width: 400px) {
        font-size: 200%;
    }
`;

export const SubHead = styled.h2 `
    margin-top: -15px;
    color: #222;
    font-weight: 400;
    font-size: 222%;
    @media screen and (max-width: 600px) {
       font-size: 230%;
       padding: 20px;
    }
    @media screen and (max-width: 400px) {
        font-size: 180%;
    }
`;