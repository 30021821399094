import React from 'react';
import Slider from '../components/compare-slider/compare-slider';
import Testimonials from '../components/testimonials/testimonials.component';
import { WorkPage, WorkSectionBreak, WorkSectionColumns, WorkSectionRow } from './our_work.styles';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050'

export default class OurWork extends React.Component {
    constructor(){
        super();

        this.state = {
            rightImage1: API_URL + '/wp-content/uploads/2022/05/IMG_0179-after.jpg',
            rightImage2: API_URL +  '/wp-content/uploads/2022/05/IMG_0792-after.jpg',
            rightImage3: API_URL + '/wp-content/uploads/2022/05/IMG_0853-before.jpg',
            rightImage4: API_URL + '/wp-content/uploads/2022/05/IMG_0782-after.jpg',
            rightImage5: API_URL + '/wp-content/uploads/2022/05/IMG_0779-after.jpg',
            rightImage6: API_URL + '/wp-content/uploads/2022/05/IMG_0633-after.jpg',
            leftImage1: API_URL + '/wp-content/uploads/2022/05/IMG_0179-before.jpg',
            leftImage2: API_URL + '/wp-content/uploads/2022/05/IMG_0792-before.jpg',
            leftImage3: API_URL + '/wp-content/uploads/2022/05/IMG_0853-after.jpg',
            leftImage4: API_URL + '/wp-content/uploads/2022/05/IMG_0782-before.jpg',
            leftImage5: API_URL + '/wp-content/uploads/2022/05/IMG_0779-before.jpg',
            leftImage6: API_URL + '/wp-content/uploads/2022/05/IMG_0633-before.jpg',
        }
    }

    render() {
        return(
            <WorkPage>
            <h2>Our Work</h2>
            <p>With every project, we deliver a thorough cleaning of your entire hood system, from the kitchen to the rooftop, and everywhere in-between. We provide inspections for any visual issues, such as loose wiring excessive grease, or worn fan belts, and make recomendations for any system improvements that will ensure your entire system can be cleaned and maintained properly.<br/><br/>Our team of professionals take on the responsibilty of tracking your schedule and calling you when your cleaning is due.<br/><br/><strong>We guarantee your satisfaction with our service!</strong></p>

            <WorkSectionRow>
                <WorkSectionColumns>
                    <Slider
                        rightImage={this.state.rightImage3}
                        leftImage={this.state.leftImage3}                
                    />
                </WorkSectionColumns>
                <WorkSectionColumns>
                    <Slider
                        rightImage={this.state.rightImage2}
                        leftImage={this.state.leftImage2}                
                    />
                </WorkSectionColumns>
                <WorkSectionColumns>
                    <Slider
                        rightImage={this.state.rightImage1}
                        leftImage={this.state.leftImage1}                
                    />
                </WorkSectionColumns>
            </WorkSectionRow>
            <WorkSectionBreak/>

            <WorkSectionRow>
                <WorkSectionColumns>
                    <Slider
                        rightImage={this.state.rightImage4}
                        leftImage={this.state.leftImage4}                
                    />
                </WorkSectionColumns>
                <WorkSectionColumns>
                    <Slider
                        rightImage={this.state.rightImage5}
                        leftImage={this.state.leftImage5}                
                    />
                </WorkSectionColumns>
                <WorkSectionColumns>
                    <Slider
                        rightImage={this.state.rightImage6}
                        leftImage={this.state.leftImage6}                
                    />
                </WorkSectionColumns>
            </WorkSectionRow>

            <Testimonials />
            </WorkPage>
                                       
        );
    }
}