import React from 'react';
import Slider from '../compare-slider/compare-slider';
import { Fade } from 'react-reveal';
import { SeeMoreButton, WorkSectionBox, WorkSectionColumns, WorkSectionRow } from './our_work_section.styles';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050'

export default class OurWorkSection extends React.Component {
    constructor(){
        super();

        this.state = {
            rightImage1: API_URL + '/wp-content/uploads/2022/05/IMG_0179-after.jpg',
            rightImage2: API_URL +  '/wp-content/uploads/2022/05/IMG_0792-after.jpg',
            leftImage1: API_URL + '/wp-content/uploads/2022/05/IMG_0179-before.jpg',
            leftImage2: API_URL + '/wp-content/uploads/2022/05/IMG_0792-before.jpg'
        }
    }

    render() {
        return(
            <WorkSectionBox>
                <Fade left><h2>See The Difference</h2></Fade>
                <Fade right><h3>Before &amp; After Our Service</h3></Fade>
                <WorkSectionRow>
                    <WorkSectionColumns>
                        <Slider
                            rightImage={this.state.rightImage1}
                            leftImage={this.state.leftImage1}                
                        />
                    </WorkSectionColumns>
                    <WorkSectionColumns>
                        <Slider
                            rightImage={this.state.rightImage2}
                            leftImage={this.state.leftImage2}                
                        />
                    </WorkSectionColumns>
                </WorkSectionRow><br/><br/>
                <SeeMoreButton to='/work'>MORE SAMPLES OF OUR WORK</SeeMoreButton>
            </WorkSectionBox>                
        );
    }
}