import styled from 'styled-components';

export const ContactRow = styled.div `
    display: flex;
    justify-content: space-between;
    background: #000;
    background-image: linear-gradient(rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/background_pattern.png);
    background-position: center;
    background-size: cover;
    width: 100vw;
    @media screen and (max-width: 700px) {
        display: block;
        padding: 20px 0 0 10px;
     }
`;

export const InfoSection = styled.div `
    width: 40%;
    @media screen and (max-width: 700px) {
        display: none;
     }
`;

export const ContactSection = styled.div `
    width: 60%;
    @media screen and (max-width: 700px) {
        width: 80%;
        margin-left: 10%;
     }
     @media screen and (max-width: 500px) {
        width: 90%;
        margin-left: 5%;
     }
`;

export const MobileDiv = styled.div `
    display: none;
    @media screen and (max-width: 700px) {
        display: block;
     }
`;