import React from "react";
import { AddressIcon, ContactInfoBlock, ImageBlock, MailIcon, PhoneIcon, VanImage } from "./contact_info.stlyes";

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050'

export const ContactInfo = () => {
    return(
        <ContactInfoBlock>
            <AddressIcon/>
            <p>P.O. Box 233 Timnath, CO 80547</p><br/>
            <PhoneIcon/>
            <a href='tel:970-219-3257'><p>Northern CO: 970-219-3257</p></a><br/>
            <PhoneIcon/>
            <a href='tel:720-219-8286'><p>Denver: 720-219-8286</p></a><br/>
            <MailIcon/>
            <a href='mailto:cameron@thehoodguy.com'><p>cameron@thehoodguy.com</p></a><br/>
            <ImageBlock>
                <VanImage src={API_URL + '/wp-content/uploads/2022/05/van_photo@300x.png'} alt='Hood Guy Van'/>
            </ImageBlock>
        </ContactInfoBlock>
    );
}