import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import GlobalStyle from './global.styles';

import { HomePage } from './pages/home';
import OurWork from './pages/our_work';
import {Apply} from './pages/apply';
import Header from './components/header/header';
import { Footer } from './components/footer/footer';

import ScrollToTop from './components/scroll_to_top';
import { ContactBlock } from './components/contact_block/contact_block';

const App = () => {

  return (
    <div>
    <GlobalStyle/>
    <Router>
    <ScrollToTop/>
      <div className='App'>
        <Header/>        
          <Routes>
            <Route path ='/' element={<HomePage /> } />
            <Route path ='/work' element={<OurWork/>} />
            <Route path ='/apply' element={<Apply/>} />
          </Routes>          
          <ContactBlock />          
        <Footer/>
      </div>  
    </Router> 
    </div>
    
  );
}

export default App;
