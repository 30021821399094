import React from 'react';

import {Flip} from 'react-reveal';

import { BlurbType, Checkmark, DarkColumn, DarkRow, InfoContain, LightColumn, LightDiv, LightDivHeader, LightRow, RedBar, Stars, Storefront, Target } from './info_block.styles';

export const InfoBlock = () => {
    return(
        <InfoContain>
            <DarkRow>
                <DarkColumn>
                    <Storefront/>                   
                    <BlurbType>Locally Owned & Operated Since 2007</BlurbType>
                </DarkColumn>
                <DarkColumn>
                    <Target/>
                    <BlurbType>Serving Colorado & Wyoming</BlurbType>
                </DarkColumn>
                <DarkColumn>
                    <Stars/>
                    <BlurbType>Deep Cleaning for All Your Equipment</BlurbType>
                </DarkColumn>
            </DarkRow>
            <LightDiv>
                <LightDivHeader><h2>What We Do?</h2></LightDivHeader>       
                <LightRow>
                    <LightColumn>
                        <Flip left><Checkmark/></Flip>
                        <p>We thoroughly clean your entire hood system, from kitchen to roof, and everywhere in between.</p>
                    </LightColumn>
                    <LightColumn>
                        <Flip left><Checkmark/></Flip>
                        <p>We implement rooftop grease containment strategies, install access panels and hinge kits to ensure your entire system can be cleaned properly. </p>
                    </LightColumn>
                    <LightColumn>
                        <Flip left><Checkmark/></Flip>
                        <p>We inspect your system for visual issues such as loose wiring, excessive grease, worn fan belts, and more. </p>                   
                    </LightColumn>
                </LightRow>
            </LightDiv>
            <RedBar>
                <h2>Your Satisfaction is Guaranteed!</h2>
            </RedBar>         
        </InfoContain>
    );
}