import styled from "styled-components";

export const FooterBlock = styled.div `
    background-color: #000;
    text-align: center;
    color: #a1a1a1;
    padding: 25px 0 10px 0;
    width: 100vw;
`;

export const FooterLogo = styled.img `
    height: 90px;
`;

export const Copy = styled.p `
    font-weight: 300;
`;