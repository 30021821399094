import React from 'react';
import { StyledBurger } from './burger-icon.styles';
import MobileMenu from '../burger-menu/burger-menu.component';

class Burger extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    toggleMenu = () => {
        this.setState(prevState => ({open: !prevState.open}));
    };

    handleLinkClick = () => {
        this.props.handleLinkClick && this.props.handleLinkClick();
    };

    render () {

        return (
            <React.Fragment>
                <StyledBurger 
                    open={this.state.open}
                    onClick={this.toggleMenu}
                    aria-label='Menu Button'
                >
                    <div />
                    <div />
                    <div />
                </StyledBurger> 
                <MobileMenu open={this.state.open} handleLinkClick={() => this.setState({open: false})} />            
            </React.Fragment>
            
        );
    }
}

export default Burger;