import React from 'react';
import axios from 'axios';
import Fade from 'react-reveal/Fade';
import {IoIosCloseCircleOutline} from 'react-icons/io';

import FormInput from '../form-input/form-input.component';
import TextArea from '../form-input/text-area.component';

import './modal.styles.scss';
import WithSpinner from '../with-spinner/with-spinner.component';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050';

axios.defaults.withCredentials = true;

class QuoteModal extends React.Component {
    constructor(){
        super();

        this.state = {
            quoteName: '',
            quoteCompany: '',
            quoteEmail: '',
            quotePhone: '',
            description: '',
            cityStateZip: '',
            quoteAddress: '',
            isLoading: false
        }
    }
    
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    clearState = () => {
        this.setState({
            quoteName: '',
            quoteCompany: '',
            quoteEmail: '',
            quotePhone: '',
            description: '',
            cityStateZip: '',
            quoteAddress: '',
            isLoading: false
    });
}
    

    handleSubmit = async event => {
        event.preventDefault();

        const {quoteName, quoteCompany, quoteEmail, quotePhone, description, quoteAddress, cityStateZip} = this.state;

        const emailBody = {
            "your-name": `${quoteName}`,
            "your-company": `${quoteCompany}`,
            "your-email": `${quoteEmail}`,
            "your-phone": `${quotePhone}`,
            "your-address": `${quoteAddress}`,
            "your-address2": `${cityStateZip}`,
            "your-description": `${description}`
        }

        const form = new FormData();
        for (const field in emailBody) {
            form.append(field, emailBody[field]);
        }

        const url = `${API_URL}/wp-json/contact-form-7/v1/contact-forms/28/feedback`;

        this.setState({isLoading: true});
            try{
                await axios.post(url, form)
                .then(function(response) {
                    if(response.data.status === 'mail_sent') {
                        window.alert("Thank Your For Your Submission. We Will Reply Shortly");
                    } else {
                        window.alert("Your Submission Failed. Please Confirm All Fields Are Valid");
                    }
                }).then(this.clearState).then(this.onClose);
            } catch(error) {
                window.alert('Something went wrong, please try again').then(this.setState({isLoading: false}));
            }

    }

    handleChange = event => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    render() {
        if(!this.props.show) {
            return null;
        }
        const { 
            quoteName, 
            quoteCompany, 
            quoteEmail, 
            quotePhone, 
            description,
            cityStateZip,
            quoteAddress,
            isLoading
        } = this.state;

        if(isLoading === false){

        return (
            <div className='modal' >
            <Fade top>
                <div className='modal-contain'>
                    <button 
                        className='close-button'
                        onClick={this.onClose}
                    ><IoIosCloseCircleOutline className='close-icon' /></button>
                    <h2 className='separator'>Free Estimate</h2>
                <form className='contact-form' onSubmit={this.handleSubmit}>
                    <div className='col-1'>
                        <FormInput 
                            type='text'
                            name='quoteName'
                            value={quoteName}
                            onChange={this.handleChange}
                            label='Name*'
                            required
                        />
                    </div>
                    <div className='col-1'>
                        <FormInput 
                            type='text'
                            name='quoteCompany'
                            value={quoteCompany}
                            onChange={this.handleChange}
                            label='Company*'
                            required
                        />
                    </div>
                    <div className='col-1'>
                        <FormInput 
                            type='email'
                            name='quoteEmail'
                            value={quoteEmail}
                            onChange={this.handleChange}
                            label='Email*'
                            required
                        />
                    </div>
                    <div className='col-1'>
                        <FormInput 
                            type='phone'
                            name='quotePhone'
                            value={quotePhone}
                            onChange={this.handleChange}
                            label='Phone*'
                            required
                        />
                    </div>
                    <div className='col-1'>
                        <FormInput 
                            type='text'
                            name='quoteAddress'
                            value={quoteAddress}
                            onChange={this.handleChange}
                            label='Company Address'
                        />
                    </div>
                    <div className='col-1'>
                        <FormInput 
                            type='text'
                            name='cityStateZip'
                            value={cityStateZip}
                            onChange={this.handleChange}
                            label='City, State and Zip'
                        />
                    </div>
                    <div className='col-1'>
                        <TextArea
                            name='description'
                            value={description}
                            onChange={this.handleChange}
                            label='Description of Work*'
                            required
                        ></TextArea>
                    </div>
                    <div className='recap'>

                    </div>
                    
                    <button className='submit-btn' type='submit'>SUBMIT</button>
                    
                </form>
                </div>
            </Fade>
            </div>
        )
        } else {
            return (
                <div className='modal' >
                    <div className='modal-contain'>
                        <WithSpinner />
                    </div>
                </div>
            )
        }
    }

}

export default QuoteModal;