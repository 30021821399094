import React from 'react';
import Burger from '../burger-icon/burger-icon.component';
import QuoteModal from '../modal-quote/modal-quote.component';
import { HeaderContainer, LogoContainer, Logo, Icon, PageLinksContainer, HeaderButton } from './header.styles';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050';

class Header extends React.Component {

    constructor(){
        super();
        this.state = {
            showEstimateModal: false
        };
    }

    onClose = () => {
        this.props.onClose && this.props.onClose();
    };

    toTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
    return(
        <div className='Header'>
            <HeaderContainer>
                <LogoContainer to='/' onClick={this.toTop}>
                    <Logo src={API_URL + '/wp-content/uploads/2022/05/hoodguy_typeonly.svg'} alt='hood guy logo'/>
                </LogoContainer>
                <Icon src={API_URL + '/wp-content/uploads/2022/05/hoodguy_icon.svg'} alt='The Hood Guy'/>
                <PageLinksContainer>
                <HeaderButton
                    onClick={() => {
                        this.setState({showEstimateModal:true});
                    }}
                >FREE ESTIMATE</HeaderButton>
                    <Burger/>
                </PageLinksContainer>                
            </HeaderContainer>

            <QuoteModal 
                    show={this.state.showEstimateModal} onClose={() => this.setState({showEstimateModal: false})}
            />
            
        </div>
    );
    }
}

export default Header;