import React from 'react';
import Fade from 'react-reveal/Fade';
import { ExperienceType, HeroLogo, LandingBlock, SubHead } from './landing.styles';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050'

const LandingHero = () => (
    <LandingBlock>
    <div className='hero-back' style={{overflow:'hidden'}}>
        <div className='images'>
            <Fade bottom big>
                <HeroLogo src={API_URL + '/wp-content/uploads/2022/05/hoodguy_main-logo.svg'} className='hero-icon' alt='icon'/>

                <ExperienceType>EXPERIENCE THE DIFFERENCE</ExperienceType>
                <SubHead>a professional hood cleaner makes</SubHead>
            </Fade>
        </div>
    </div>
    </LandingBlock>
    
);

export default LandingHero;