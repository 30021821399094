import React, {Component} from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import {RiChatQuoteLine} from 'react-icons/ri';

import './testimonials.styles.scss';

//https://dev.to/aryaziai/modern-testimonials-in-react-tutorial-cn1

export default class Testimonials extends Component {
    render() {
        return ( 
            <div className ='testimonials-block'>
            <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={3000}
            >
                <div>
                    <RiChatQuoteLine className='quoteIcon'/>
                    <div className='myCarousel'>
                        <h3>Dependable</h3>
                        <h4>Dave Daggett</h4>
                        <h5>Chef / Owner<br/>RISE! A Breakfast Place</h5>
                        <p>“RISE! has used The Hood Guy for the last five years. There service is awesome! They call on a regular basis to schedule cleaning, always willing to work around our schedule. The cleaning crew is very professional, dependable and always leaves our kitchen clean and in great shape!  I highly recommend their services!”</p>                      
                    </div>  
                </div>
                <div>
                    <RiChatQuoteLine className='quoteIcon'/>
                    <div className='myCarousel'>
                        <h3>Highly Recommended</h3>
                        <h4>Jeff Gilchrist</h4>
                        <h5>Director of Culinary Services<br/>The Suites Ft. Collins</h5>
                        <p>"I have used The Hood Guy for more than ten years now in multiple locations that I have worked in. Any time I go to a new location The Hood Guy is one of the first people I get on my vendor list. The work is always done quickly, thoroughly, and for a very fair price.  I highly recommend Cameron Cain and his staff for all your hood cleaning needs."</p>                      
                    </div>  
                </div>
                <div>
                    <RiChatQuoteLine className='quoteIcon'/>
                    <div className='myCarousel'>
                        <h3>Communication</h3>
                        <h4>Jason Shaeffer</h4>
                        <h5>Owner/Chef<br/>Chimney Park Restaurant & Bar<br/>Hearth Restaurant and Pub</h5>
                        <p>"We have been using Cameron from The Hood Guy for 7 years after switching from a different service provider.  His appointment calls, service to clean our hoods and follow up communication at both restaurants are always impeccable.  We would highly recommend using The Hood Guy to fellow restaurateurs."</p>                      
                    </div>  
                </div>
                <div>
                    <RiChatQuoteLine className='quoteIcon'/>
                    <div className='myCarousel'>
                        <h3>The best</h3>
                        <h4>Kevin Gabauer</h4>
                        <h5>Fat Shack</h5>
                        <p>"Our team has worked with several different hood cleaning companies in several states over the years and The Hood Guy continues to be the best.  Cameron and his team are consistent and reliable.  Great work and reasonable pricing."</p>                      
                    </div>  
                </div>
                <div>
                    <RiChatQuoteLine className='quoteIcon'/>
                    <div className='myCarousel'>
                        <h3>Quality</h3>
                        <h4>Billy Charters</h4>
                        <h5>Executive Chef</h5>
                        <p>"I’ve used The Hood Guy for 10 years and recommend them to anyone – fair price, quality work, always do the right thing."</p>                      
                    </div>  
                </div>
            </Carousel>
            </div>
        );
    }
}