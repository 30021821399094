import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div `
    background-color: rgba(0, 0, 0, 0.98);
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.25);
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 10;
    @media screen and (max-width: 800px) {
        height: 80px;
    }
`;

export const LogoContainer = styled(Link)
`
    height: 100%;
    width: 25%;
    min-width: 250px;
    display: flex;
    align-items: center;    
    @media screen and (max-width: 800px) {
        width: 30%;
        min-width: 150px;
    }
    @media screen and (max-width: 600px) {
        width: 45%;
    }
`;

export const Logo = styled.img `
    width: 100%;
    padding: 2px 0 2px 5px;
    margin-left: 5%;
    @media screen and (max-width: 600px) {
        padding: 0 0 0 5px;
    }
`

export const Icon = styled.img `
    width: 8%;
    padding: 8px 0 8px 5px;
    margin-left: 22%;
    @media screen and (max-width: 700px) {
       display: none;
    }
`

export const PageLinksContainer = styled.div `
    height: 100%;
    width: 45%;
    display: flex;
    margin-right: 2%;
    align-items: right;
    justify-content: flex-end;
    @media screen and (max-width: 600px) {
        margin-top: 20px;
    }
`;

export const HeaderButton = styled.button `
    background-color: #cf202c;
    border-radius: 50px;
    height: 30px;
    color: #000;
    font-weight: bold;
    border: 1px solid #cf202c;
    padding: 6px 10px 10px 10px;
    margin: 18px 60px 20px 0;
    z-index: 800;
    &:hover {
        background-color: #fff;
        color: #cf202c;
        border: solid 1px #cf202c;
        cursor: pointer;
    }
    @media screen and (max-width: 600px) {
        margin-top: 5px;
    }
    @media screen and (max-width: 420px) {
        padding: 2px 10px;
        font-size: 70%;
    }
`;