import React from 'react';
import {bool} from 'prop-types';

import {StyledMenu, PageLink} from './burger-menu.styles';


class MobileMenu extends React.Component {
    constructor(props) {
        super(props);

        MobileMenu.propTypes = {
            open: bool.isRequired,
          }

        this.state = {
            open: this.props.open
        };
    }

    onClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleLinkClick = e => {
        this.props.handleLinkClick && this.props.handleLinkClick(e);
        window.scrollTo(0,0);
    };

    render() {

        return (
            <React.Fragment>
            <StyledMenu open={this.props.open}>
                <PageLink 
                    to='/'
                    onClick={this.handleLinkClick}
                >Home</PageLink>               
                <PageLink 
                    to='/work'
                    onClick={this.handleLinkClick}
                >Our Work</PageLink>                
                <PageLink 
                    to='/apply'
                    onClick={this.handleLinkClick}
                >Work For Us</PageLink>
            </StyledMenu>
            </React.Fragment>
        );
    }    
}
export default MobileMenu;

/*

                <PageLink 
                    to='/'
                    onClick={this.handleLinkClick}
                >About Us</PageLink>
                <PageLink 
                    to='/'
                    onClick={this.handleLinkClick}
                >Testimonials</PageLink>

*/