import React from 'react';

import './form-input.styles.scss';

const TextArea = ({handleChange, label, ...otherProps}) => (
    <div className='group'>
        <textarea rows='4' className='form-input form-text' onChange={handleChange} {...otherProps} />
        {
            label ?
            (<label className={`${otherProps.value.length ? 'shrink' : ''} form-input-label`}>
                {label}
            </label>)
            :null
        }
    </div>
)

export default TextArea;