import React from 'react';

import {Copy, FooterBlock, FooterLogo} from './footer.styles';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050'

export const Footer = () => {
    return(
        <FooterBlock>
            <FooterLogo src={API_URL + '/wp-content/uploads/2022/05/hoodguy_main-logo.svg'} alt='Hood Guy Logo'/>
            <Copy><span dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> 2022</Copy>
        </FooterBlock>        
    );
}