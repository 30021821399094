import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const WorkSectionBox = styled.div `
    margin-top: 0;
    padding: 20px 6% 80px;
    text-align: center;
    width: 100vw;
    background-color: #000;
    background-image: linear-gradient(rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/background_pattern.png);
    background-position: center;
    background-size: cover;
    color: #fff;
    h2 {
        margin-top: 0;
        color: #fff;
        font-size: 225%;
        font-weight: 500;
        text-transform: uppercase;
    }
    h3 {
        margin-top: -35px;
        color: #fff;
        font-size: 185%;
        font-weight: 300;
    }
    @media screen and (max-width: 400px) {
        h2{font-size: 185%;}
        h3{font-size: 155%;margin-top:-15px;}
    }
`;

export const WorkSectionRow = styled.div `
    width: 100%;   
    display: flex; 
    justify-content: space-between;
    margin: 0;
    @media screen and (max-width: 700px) {
        display: block;
     }
`;

export const WorkSectionColumns = styled.div `
    width: 48%;
    @media screen and (max-width: 700px) {
        width: 90%;
        margin-left: 5%;
        padding: 15px;
     }
`;

export const SeeMoreButton = styled(Link)
`
    background-color: #000;
    border-radius: 50px;
    height: 30px;
    color: #cf202c;
    font-weight: bold;
    border: 1px solid #cf202c;
    padding: 6px 15px 10px 15px;
    margin-top: 50px;
    &:hover {
        background-color: #cf202c;
        color: #000;
        border: solid 1px #cf202c;
        cursor: pointer;
    }
`;