import React from "react";

import {ContactInfo} from "../contact_info/contact_info";
import { ContactRow, ContactSection, InfoSection, MobileDiv } from "./contact_block.styles";
import ContactUs from "../contact-form/contact-form.component";
import { ContactInfoMobile } from "../contact_info/contact_info_mobile";

export const ContactBlock = () => {
    return(
        <ContactRow>
            <InfoSection><ContactInfo/></InfoSection>
            <ContactSection><ContactUs/></ContactSection>
            <MobileDiv><ContactInfoMobile/></MobileDiv>
        </ContactRow>
        
    );
}