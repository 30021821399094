import styled from 'styled-components';

export const ApplyPage = styled.div `
    padding: 70px 20% 30px;
    @media screen and (max-width: 700px) {
        padding: 70px 5% 30px;
       }

    h1 {
        text-align: center;
        font-size: 225%;
        font-weight: 500;
        text-transform: uppercase;
        color: #cf202c;
    }

    p{
        width: 80%;
        margin-left: 10%;
        color: #666;
    }

    label{
        color: grey;
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
    }
`;

export const FormRow = styled.div `
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    @media screen and (max-width: 700px) {
       display: block;
       margin: unset;
      }
`;

export const Col1 = styled.div `
    width: 100%;
`;

export const Col2 = styled.div `
    width: 48%;
    @media screen and (max-width: 700px) {
       width: 100%;
     }
`;

export const Col3 = styled.div `
    width: 30%;
    @media screen and (max-width: 700px) {
        width: 100%;
      }
`;

export const ApplyButton = styled.button `
    background-color: #fff;
    border-radius: 50px;
    height: 30px;
    color: #cf202c;
    font-weight: bold;
    border: 1px solid #cf202c;
    padding:6px 12px 32px 12px;
    margin: 0 10px;
    font-size: 150%;
    font-weight: 500;
    &:hover {
        background-color: #cf202c;
        border: solid 1px #cf202c;
        color: #fff;
        cursor: pointer;
    }
    &:active {
        position: relative;
        top: 1px;
    }
`;