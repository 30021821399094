import styled from 'styled-components';

export const StyledBurger = styled.button `
position: absolute;
top: 15%;
display: flex;
flex-direction: column;
justify-content: space-around;
width: 2.5rem;
height: 2.5rem;
background: transparent;
border: none;
cursor: pointer;
padding: 0;
z-index: 800;
&:focus {
    outline: none;
}
@media screen and (max-width: 600px) {
    top: 25%;
}

div {
    width: 2.5rem;
    height: 0.25rem;
    background: #cf202c;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
        opacity: ${({ open }) => open ? '0' : '1'};
        transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
}
`;