import styled from "styled-components";

import { IoStorefrontOutline } from 'react-icons/io5';
import { WiStars } from 'react-icons/wi';
import { ImTarget } from 'react-icons/im';
import { BsCheck2Circle } from 'react-icons/bs';

export const InfoContain = styled.div `
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100vw;
`;

export const DarkRow = styled.div `
    background-color: #000;
    background-image: linear-gradient(rgba(0,0,0, 0.9), rgba(0,0,0, 0.9)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/background_pattern.png);
    background-position: center;
    background-size: cover; 
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: solid 5px #cf202c;
    @media screen and (max-width: 800px) {
       display: block;
    }
    @media screen and (max-width: 400px) {
        font-size: 95%;
    }    
`;

export const DarkColumn = styled.div `
    padding: 6%;
    text-align: center;
    color: #fff;
    @media screen and (max-width: 400px) {
        padding: 5% 10%;
    }
`;

export const Storefront = styled(IoStorefrontOutline)
`
    color: #cf202c;
    font-size: 400%;
`;

export const Stars = styled(WiStars)
`
    color: #cf202c;
    font-size: 400%;
`;

export const Target = styled(ImTarget)
`
    color: #cf202c;
    stroke: #cf202c;
    font-size: 400%;
`;

export const BlurbType = styled.h2 `
    font-weight: 400;
    font-size: 160%;
`;

export const Checkmark = styled(BsCheck2Circle)
`
    color: #cf202c;
    font-size: 300%;
`;

export const LightDiv = styled.div `
    background-image: linear-gradient(rgba(255,255,255, 0.8), rgba(255,255,255, 0.8)), url(https://server.thehoodguy.com/wp-content/uploads/2022/05/hero_photo@300x.png);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    margin-top: 0;
    @media screen and (max-width: 700px) {
        background-attachment: unset;
     }
    
`;

export const LightDivHeader = styled.div `
    margin: 0;
    padding: 0;
    text-align: center;
    h2{
        color: #cf202c;
        font-size: 250%;
        font-weight: 400;
    }
    @media screen and (max-width: 400px) {
        font-size: 90%;
    }
`;

export const LightRow = styled.div ` 
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 800px) {
        display: block;
     }
`;

export const LightColumn = styled.div `
    padding: 0 6% 4%;
    text-align: center;
    @media screen and (max-width: 800px) {
        p{
            font-size: 130%;
        }
     }
     @media screen and (max-width: 400px) {
        p{
            font-size: 115%;
        }
    }
`;

export const RedBar = styled.div `
    background-color: #cf202c;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
    h2{ 
        font-weight: 400;
        font-size: 250%;
    }
    @media screen and (max-width: 600px) {
       h2{
           font-size: 180%;
       }
    }
`;