import styled from "styled-components";

import { HiHome, HiPhone, HiMail } from 'react-icons/hi';

export const ContactInfoBlock = styled.div `
    text-align: center;
    color: #fff;
    padding: 30px 0;
    @media screen and (max-width: 700px) {
        padding: 10px 0;
        margin-top: 30px;
     }

    p {
        color: #fff;
        &:hover {
            color: rgba(255,255,255,0.7);
            cursor: pointer;
        }
        @media screen and (max-width: 700px) {
            font-size: 130%;
         }
        @media screen and (max-width: 700px){
            font-size: 100%;
        }
    }

    a {
        color: #fff;
        &:hover {
            color: rgba(255,255,255,0.7);
        }
    }
    
`;

export const AddressIcon = styled(HiHome)
`
    color: #cf202c;
    font-size: 200%;
    @media screen and (max-width: 700px) {
        font-size: 260%;
     }
`;

export const PhoneIcon = styled(HiPhone)
`
    color: #cf202c;
    font-size: 200%;
    @media screen and (max-width: 700px) {
        font-size: 260%;
     }
`;

export const MailIcon = styled(HiMail)
`
    color: #cf202c;
    font-size: 200%;
    @media screen and (max-width: 700px) {
        font-size: 260%;
     }
`;

export const ImageBlock = styled.div `
    max-height: 200px;
`;

export const VanImage = styled.img `
    max-width: 60%;
`;