import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle `
    html, body {
        height: 100%;
    }

    body {
        font-family: Open Sans, sans-serif;
        overflow-x: hidden;
        padding: 0;
        margin: 0;
    }

    *{
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: #444;
    }

    li {
        list-style: none;
    }

    ::-webkit-scrollbar {
        width: 6px;
        background-color: #eee;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #333;
        border-radius: .5px;
    }
`;

export default GlobalStyle;