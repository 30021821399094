import React from 'react';
import ReactCompareImage from 'react-compare-image';

export default class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leftImage: props.leftImage,
            rightImage: props.rightImage
        }
    }

    render() {
        return (
         <ReactCompareImage 
            leftImage={this.state.leftImage}
            leftImageAlt='Before Our Services'
            leftImageLabel='Before Our Services'
            rightImage={this.state.rightImage}
            rightImageAlt='After Our Services'
            rightImageLabel='After Our Services'
            sliderLineColor='#cf202c'
            sliderPositionPercentage={0.65}
         />
        );
    }
}