import React from 'react';
import axios from 'axios';

import FormInput from '../components/form-input/form-input.component';
import FormSelect from '../components/form-input/form-select.component';
import { ApplyButton, ApplyPage, Col1, Col2, Col3, FormRow } from './apply.styles';
import TextArea from '../components/form-input/text-area.component';
import WithSpinner from '../components/with-spinner/with-spinner.component';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5050';

axios.defaults.withCredentials = true;

export class Apply extends React.Component {
    constructor(){
        super();

        this.state = {
            firstName: '',
            middleInitial: '',
            lastName: '',
            birthMonth: '',
            birthDay: '',
            birthYear: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            linkedIn: '',
            file: null,
            coverLetter: '',
            isLoading: false
        }
    }

    clearState = () => {
        this.setState({
            firstName: '',
            middleInitial: '',
            lastName: '',
            birthMonth: '',
            birthDay: '',
            birthYear: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            phone: '',
            linkedIn: '',
            file: null,
            coverLetter: '',
            isLoading: false
    });
    }

    handleSubmit = async event => {
        event.preventDefault();

        const {firstName, middleInitial, lastName, birthMonth, birthDay, birthYear,addressLine1, addressLine2, city, state, zip, email, phone, linkedIn, coverLetter} = this.state;

        const name = `${firstName} ${middleInitial} ${lastName}`
        const birthDate = `${birthMonth}/${birthDay}/${birthYear}`

        const form = new FormData();
        form.append('your-file', this.state.file);
        form.append('your-name', name);
        form.append('your-birthday', birthDate);
        form.append('your-address1', addressLine1);
        form.append('your-address2', addressLine2);
        form.append('your-city', city);
        form.append('your-state', state);
        form.append('your-zip', zip);
        form.append('your-phone', phone);
        form.append('your-email', email);
        form.append('your-linked-in', linkedIn);
        form.append('your-cover-letter', coverLetter);

        const url = `${API_URL}/wp-json/contact-form-7/v1/contact-forms/30/feedback`;

        this.setState({isLoading: true})
        try {
                    await axios.post(url, form)
                    .then(function(response) {
                        if(response.data.status === 'mail_sent') {
                            window.alert("Thank Your For Your Interest. We will review your application and get back with you.")
                        } else {
                            window.alert(`Your Submission Failed. ${response.data.message}`).then(this.setState({isLoading: false}));
                        }
                    }).then(this.clearState);
            } catch (error) {
                window.alert("Something Went Wrong, Please Try Again Later");
                this.setState({isLoading: false});
                return;
            }

    }

    handleChange = event => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    onChange = (e) => {
        this.setState({file: e.target.files[0]});
    }
/*
    handleToken = async (token) => {
        this.setState({token: token});
        const token_req = {
            "token": `${token}`
        }
        try{
            await axios.post(`${API_URL}/recaptcha.php`, token_req).then(res => {
                console.log(res);
                    if(res.data === 'pass'){
                        this.setState({captchaSuccess: true});
                        this.setState({disabled: false});
                        
                    } else {
                        console.log('fail');
                    }
            });
        }catch{
            this.setState({isLoading: false});
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        const {firstName, middleInitial, lastName, birthMonth, birthDay, birthYear,addressLine1, addressLine2, city, state, zip, email, phone, linkedIn, coverLetter} = this.state;

        const name = `${firstName} ${middleInitial} ${lastName}`
        const birthDate = `${birthMonth}/${birthDay}/${birthYear}`

        const form = new FormData();
        form.append('your-file', this.state.file);
        form.append('your-name', name);
        form.append('your-birthday', birthDate);
        form.append('your-address1', addressLine1);
        form.append('your-address2', addressLine2);
        form.append('your-city', city);
        form.append('your-state', state);
        form.append('your-zip', zip);
        form.append('your-phone', phone);
        form.append('your-email', email);
        form.append('your-linked-in', linkedIn);
        form.append('your-cover-letter', coverLetter);

        const url = `${API_URL}/wp-json/contact-form-7/v1/contact-forms/30/feedback`;

        this.setState({isLoading: true})
        if(this.state.captchaSuccess === true){
            try {
                    await axios.post(url, form)
                    .then(function(response) {
                        console.log(response.data.status);
                        if(response.data.status === 'mail_sent') {
                            window.alert("Thank Your For Your Interest. We will review your application and get back with you.")
                        } else {
                            window.alert(`Your Submission Failed. ${response.data.message}`).then(this.setState({isLoading: false}));
                        }
                    }).then(this.clearState);
            } catch (error) {
                console.log(error);
                window.alert("Something Went Wrong, Please Try Again Later");
                this.setState({isLoading: false});
                return;
            }
        } else {
            window.alert('Google ReCaptcha Failed. Please check your browser settings and try again.');
                this.setState({isLoading: false});
        }

    }

    handleExpired = () => {
        this.setState({token: null})
    } */

    render() {

        const {firstName, middleInitial, lastName, birthMonth, birthDay, birthYear,addressLine1, addressLine2, city, state, zip, email, phone, linkedIn, coverLetter, isLoading} = this.state;

        if(isLoading === false){
        return (
            <ApplyPage>
                <h1>Apply For Work</h1>
                <p>We are always looking for hard-working candidates to add to our growing team. Please fill out the below form, and we will follow up with you as soon as possible.</p><br/><br/>
                <form onSubmit={this.handleSubmit}>
                <FormRow>
                    <Col3>
                        <FormInput 
                            type='text'
                            name='firstName'
                            value={firstName}
                            onChange={this.handleChange}
                            label='First Name*'
                            required
                        />
                    </Col3>
                    <Col3>
                        <FormInput 
                            type='text'
                            name='middleInitial'
                            value={middleInitial}
                            onChange={this.handleChange}
                            label='Middle Initial'
                        />
                    </Col3>
                    <Col3>
                        <FormInput 
                            type='text'
                            name='lastName'
                            value={lastName}
                            onChange={this.handleChange}
                            label='Last Name*'
                            required
                        />
                    </Col3>
                </FormRow>
                <FormRow>
                    <Col3>
                        <FormSelect
                            name='birthMonth'
                            value={birthMonth}
                            onChange={this.handleChange}
                            label='Birth Month*'
                            required
                        >
                            <option disabled></option>
                            <option value='01'>January</option>
                            <option value='02'>February</option>
                            <option value='03'>March</option>
                            <option value='04'>April</option>
                            <option value='05'>May</option>
                            <option value='06'>June</option>
                            <option value='07'>July</option>
                            <option value='08'>August</option>
                            <option value='09'>September</option>
                            <option value='10'>October</option>
                            <option value='11'>November</option>
                            <option value='12'>December</option>
                        </FormSelect>
                    </Col3>
                    <Col3>
                        <FormSelect
                            name='birthDay'
                            value={birthDay}
                            onChange={this.handleChange}
                            label='Birth Day*'
                            required
                        >
                            <option disabled></option>
                            <option value='01'>01</option>
                            <option value='02'>02</option>
                            <option value='03'>03</option>
                            <option value='04'>04</option>
                            <option value='05'>05</option>
                            <option value='06'>06</option>
                            <option value='07'>07</option>
                            <option value='08'>08</option>
                            <option value='09'>09</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                            <option value='15'>15</option>
                            <option value='16'>16</option>
                            <option value='17'>17</option>
                            <option value='18'>18</option>
                            <option value='19'>19</option>
                            <option value='20'>20</option>
                            <option value='20'>20</option>
                            <option value='21'>21</option>
                            <option value='22'>22</option>
                            <option value='23'>23</option>
                            <option value='24'>24</option>
                            <option value='25'>25</option>
                            <option value='26'>26</option>
                            <option value='27'>27</option>
                            <option value='28'>28</option>
                            <option value='29'>29</option>
                            <option value='30'>30</option>
                            <option value='31'>31</option>
                        </FormSelect>
                    </Col3>
                    <Col3>
                        <FormSelect
                            name='birthYear'
                            value={birthYear}
                            onChange={this.handleChange}
                            label='Birth Year*'
                            required
                        >
                            <option disabled></option>
                            <option value='2005'>2005</option>
                            <option value='2004'>2004</option>
                            <option value='2003'>2003</option>
                            <option value='2002'>2002</option>
                            <option value='2001'>2001</option>
                            <option value='1999'>1999</option>
                            <option value='1998'>1998</option>
                            <option value='1997'>1997</option>
                            <option value='1996'>1996</option>
                            <option value='1995'>1995</option>
                            <option value='1994'>1994</option>
                            <option value='1993'>1993</option>
                            <option value='1992'>1992</option>
                            <option value='1991'>1991</option>
                            <option value='1990'>1990</option>
                            <option value='1989'>1989</option>
                            <option value='1988'>1988</option>
                            <option value='1987'>1987</option>
                            <option value='1986'>1986</option>
                            <option value='1985'>1985</option>
                            <option value='1984'>1984</option>
                            <option value='1983'>1983</option>
                            <option value='1982'>1982</option>
                            <option value='1981'>1981</option>
                            <option value='1980'>1980</option>
                            <option value='1979'>1979</option>
                            <option value='1978'>1978</option>
                            <option value='1977'>1977</option>
                            <option value='1976'>1976</option>
                            <option value='1975'>1975</option>
                            <option value='1974'>1974</option>
                            <option value='1973'>1973</option>
                            <option value='1972'>1972</option>
                            <option value='1971'>1971</option>
                            <option value='1970'>1970</option>
                            <option value='1969'>1969</option>
                            <option value='1968'>1968</option>
                            <option value='1967'>1967</option>
                            <option value='1966'>1966</option>
                            <option value='1965'>1965</option>
                            <option value='1964'>1964</option>
                            <option value='1963'>1963</option>
                            <option value='1962'>1962</option>
                            <option value='1961'>1961</option>
                            <option value='1960'>1960</option>
                            <option value='1959'>1959</option>
                            <option value='1958'>1958</option>
                            <option value='1957'>1957</option>
                            <option value='1956'>1956</option>
                            <option value='1955'>1955</option>
                            <option value='1954'>1954</option>
                            <option value='1953'>1953</option>
                            <option value='1952'>1952</option>
                            <option value='1951'>1951</option>
                            <option value='1950'>1950</option>
                            <option value='1950'>1950</option>
                            <option value='1949'>1949</option>
                            <option value='1948'>1948</option>
                            <option value='1947'>1947</option>
                            <option value='1946'>1946</option>
                            <option value='1945'>1945</option>
                            <option value='1944'>1944</option>
                            <option value='1943'>1943</option>
                            <option value='1942'>1942</option>
                            <option value='1941'>1941</option>
                            <option value='1940'>1940</option>
                            <option value='1939'>1939</option>
                            <option value='1938'>1938</option>
                            <option value='1937'>1937</option>
                            <option value='1936'>1936</option>
                            <option value='1935'>1935</option>
                            <option value='1934'>1934</option>
                            <option value='1933'>1933</option>
                            <option value='1932'>1932</option>
                            <option value='1931'>1931</option>
                            <option value='1930'>1930</option>
                        </FormSelect>
                    </Col3>
                </FormRow>
                <FormRow>
                    <Col1>
                        <FormInput 
                            type='text'
                            name='addressLine1'
                            value={addressLine1}
                            onChange={this.handleChange}
                            label='Street Address*'
                            required
                        />
                    </Col1>
                </FormRow>
                <FormRow>
                    <Col1>
                        <FormInput 
                            type='text'
                            name='addressLine2'
                            value={addressLine2}
                            onChange={this.handleChange}
                            label='Street Address Line 2'
                        />
                    </Col1>
                </FormRow>
                <FormRow>
                    <Col3>
                        <FormInput 
                            type='text'
                            name='city'
                            value={city}
                            onChange={this.handleChange}
                            label='City*'
                            required
                        />
                    </Col3>
                    <Col3>
                        <FormSelect
                            name='state'
                            value={state}
                            onChange={this.handleChange}
                            label='State*'
                            required
                        >
                            <option disabled></option>
                            <option value="AK">Alaska</option>
                            <option value="AL">Alabama</option>
                            <option value="AR">Arkansas</option>
                            <option value="AS">American Samoa</option>
                            <option value="AZ">Arizona</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Conneticut</option>
                            <option value="DC">District of Colombia</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="FM">Federated States</option>
                            <option value="GA">Georgia</option>
                            <option value="GU" disabled >Guam</option>
                            <option value="HI">Hawaii</option>
                            <option value="IA">Iowa</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MD">Maryland</option>
                            <option value="ME">Maine</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MO">Missouri</option>
                            <option value="MS">Mississippi</option>
                            <option value="MT">Montana</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="NE">Nebraska</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NV">Nevada</option>
                            <option value="NY">New York</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR" disabled >Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VI" disabled >Virgin Islands</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WV">West Virginia</option>
                            <option value="WY">Wyoming</option> 
                        </FormSelect>
                    </Col3>
                    <Col3>
                        <FormInput 
                            type='text'
                            name='zip'
                            value={zip}
                            onChange={this.handleChange}
                            label='ZIP*'
                            required
                        />
                    </Col3>
                </FormRow>
                <FormRow>
                    <Col2>
                        <FormInput 
                            type='text'
                            name='email'
                            value={email}
                            onChange={this.handleChange}
                            label='Email Address*'
                            required
                        />
                    </Col2>
                    <Col2>
                        <FormInput 
                            type='text'
                            name='phone'
                            value={phone}
                            onChange={this.handleChange}
                            label='Phone Number*'
                            required
                        />
                    </Col2>
                </FormRow>
                <FormRow>
                    <Col2>
                        <FormInput 
                            type='text'
                            name='linkedIn'
                            value={linkedIn}
                            onChange={this.handleChange}
                            label='LinkedIn Account'
                        />
                    </Col2>
                    <Col2>
                        <label>Upload Your Resume* <small>5MB or less, Word or PDF</small></label><br/>
                        <input 
                            type='file'
                            onChange={this.onChange}
                            required
                        />
                    </Col2>
                </FormRow>
                <FormRow>
                    <Col1>
                        <TextArea 
                            name='coverLetter'
                            value={coverLetter}
                            onChange={this.handleChange}
                            label='Cover Letter*'
                            required
                        />
                    </Col1>                    
                </FormRow>
                 
                <ApplyButton type='submit'>SUBMIT APPLICATION</ApplyButton>
                </form>
            </ApplyPage>
        )
        }else{
            return(
                <ApplyPage>
                <h1>Your Application is Submitting</h1>
                <WithSpinner />
                </ApplyPage>
            )
        }
    }
}