import React from 'react';
import { InfoBlock } from '../components/info_block/info_block';
import LandingHero from '../components/landing_hero/landing_hero';
import OurWorkSection from '../components/our_work_section/our_work_section';
import Testimonials from '../components/testimonials/testimonials.component';

export const HomePage = () => {

    return (
        <React.Fragment>
            <LandingHero/>
            <InfoBlock />
            <OurWorkSection />
            <Testimonials />
        </React.Fragment>
    );
}